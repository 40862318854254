.sprite-bg  {
  background-image: url('../../img/sprite.png');
  background-repeat: no-repeat;
  background-size: 541px 438px;
  text-indent: -9999px;
  white-space: nowrap;
}

.logo-icon {
  background-position: -4px -4px;
  width: 84px;
  height: 32px;
  margin-right: 32px;
}

.search-icon {
  background-position: -232px -4px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 8px;
  top: 6px;
}

.search-prev {
  background-position: -96px -4px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.rs-icon, .en-icon {
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: 1px 1px 4px -1px #676666;
}

.rs-icon {
  background-position: -130px -4px;
  width: 26px;
  height: 20px;
}

.en-icon {
  background-position: -164px -4px;
  width: 26px;
  height: 20px;
}

.account-icon {
  background-position: -260px -4px;
  width: 16px;
  height: 20px;
}

.favorite-icon-line {
  background-position: -284px -4px;
  width: 14px;
  height: 13px;
  margin-right: 8px;
}

.saving-icon-line {
  background-position: -306px -4px;
  width: 12px;
  height: 16px;
  margin-right: 8px;
}

.like-icon, .guide-icon, .web-icon, .ms-icon, .tutorial-icon, .login-icon, .email-icon {
  margin-right: 16px;
}

.like-icon {
  background-position: -358px -4px;
  width: 22px;
  height: 20px;
}

.guide-icon {
  background-position: -388px -4px;
  width: 22px;
  height: 16px;
}

.web-icon {
  background-position: -418px -4px;
  width: 20px;
  height: 20px;
}

.ms-icon {
  background-position: -446px -4px;
  width: 19px;
  height: 25px;
}

.tutorial-icon {
  background-position: -473px -4px;
  width: 20px;
  height: 18px;
}

.login-icon {
  background-position: -501px -4px;
  width: 21px;
  height: 21px;
}

.cat-1-icon-big {
  background-position: -229px -84px;
  width: 26px;
  height: 23px;
}

.cat-2-icon-big {
  background-position: -264px -84px;
  width: 27px;
  height: 25px;
}

.cat-3-icon-big {
  background-position: -361px -84px;
  width: 28px;
  height: 25px;
}

.cat-4-icon-big {
  background-position: -299px -85px;
  width: 22px;
  height: 26px;
}

.cat-5-icon-big {
  background-position: -329px -84px;
  width: 25px;
  height: 25px;
}

.cat-6-icon-big {
  background-position: -397px -86px;
  width: 23px;
  height: 23px;
}

.cat-7-icon-big {
  background-position: -515px -412px;
  background-size: 541px 438px;
  width: 26px;
  height: 26px;
}

.cat-8-icon-big {
  background-position: -458px -84px;
  width: 24px;
  height: 24px;
}

.cat-9-icon-big {
  background-position: -490px -84px;
  width: 20px;
  height: 26px;
}

.cat-10-icon-big {
  background-position: -229px -120px;
  width: 26px;
  height: 21px;
}

.cat-11-icon-big {
  background-position: -263px -119px;
  width: 18px;
  height: 27px;
}

.cat-12-icon-big {
  background-position: -289px -119px;
  width: 24px;
  height: 26px;
}

.cat-13-icon-big {
  background-position: -321px -119px;
  width: 16px;
  height: 25px;
}

.cat-14-icon-big {
  background-position: -345px -119px;
  width: 25px;
  height: 25px;
}

.cat-15-icon-big {
  background-position: -378px -119px;
  width: 26px;
  height: 25px;
}

.cat-16-icon-big {
  background-position: -412px -119px;
  width: 25px;
  height: 24px;
}

.cat-19-icon-big {
  background-position: -165px -314px;
  width: 26px;
  height: 17px;
}

.cat-20-icon-big {
  background-position: -233px -314px;
  width: 23px;
  height: 28px;
}


.cat-1-icon-normal {
  background-position: -4px -132px;
  width: 20px;
  height: 18px;
}

.cat-2-icon-normal {
  background-position: -32px -132px;
  width: 19px;
  height: 18px;
}

.cat-3-icon-normal {
  background-position: -108px -132px;
  width: 20px;
  height: 18px;
}

.cat-4-icon-normal {
  background-position: -59px -132px;
  width: 16px;
  height: 18px;
}

.cat-5-icon-normal {
  background-position: -83px -132px;
  width: 18px;
  height: 18px;
}

.cat-6-icon-normal {
  background-position: -136px -132px;
  width: 18px;
  height: 18px;
}

.cat-7-icon-normal {
  background-position: -147px -410px;
  width: 36px;
  height: 18px;
}

.cat-8-icon-normal {
  background-position: -186px -131px;
  width: 17px;
  height: 17px;
}

.cat-9-icon-normal {
  background-position: -4px -159px;
  width: 14px;
  height: 18px;
}

.cat-10-icon-normal {
  background-position: -26px -159px;
  width: 21px;
  height: 18px;
}

.cat-11-icon-normal {
  background-position: -56px -159px;
  width: 13px;
  height: 18px;
}

.cat-12-icon-normal {
  background-position: -77px -159px;
  width: 16px;
  height: 18px;
}

.cat-13-icon-normal {
  background-position: -102px -159px;
  width: 12px;
  height: 18px;
}

.cat-14-icon-normal {
  background-position: -122px -159px;
  width: 18px;
  height: 18px;
}

.cat-15-icon-normal {
  background-position: -206px -136px;
  width: 19px;
  height: 18px;
}

.cat-19-icon-normal {
  background-position: -165px -338px;
  width: 21px;
  height: 13px;
}

.cat-20-icon-normal {
  background-position: -294px -314px;
  width: 16px;
  height: 19px;
}

.cat-1-icon-small {
  background-position: -88px -183px;
  width: 30px;
  height: 23px;
}

.cat-2-icon-small {
  background-position: -110px -182px;
  width: 30px;
  height: 23px;
}

.cat-3-icon-small {
  background-position: -395px -183px;
  width: 31px;
  height: 23px;
}

.cat-4-icon-small {
  background-position: -149px -183px;
  width: 27px;
  height: 23px;
}

.cat-5-icon-small {
  background-position: -168px -183px;
  width: 27px;
  height: 23px;
}

.cat-6-icon-small {
  background-position: -189px -182px;
  width: 27px;
  height: 23px;
}

.cat-7-icon-small {
  background-position: -363px -374px;
  width: 28px;
  height: 19px;
}

.cat-8-icon-small {
  background-position: -229px -182px;
  width: 28px;
  height: 23px;
}

.cat-9-icon-small {
  background-position: -250px -183px;
  width: 26px;
  height: 23px;
}

.cat-10-icon-small {
  background-position: -268px -181px;
  width: 30px;
  height: 23px;
}

.cat-11-icon-small {
  background-position: -291px -183px;
  width: 25px;
  height: 23px;
}

.cat-12-icon-small {
  background-position: -308px -183px;
  width: 29px;
  height: 23px;
}

.cat-13-icon-small {
  background-position: -132px -183px;
  width: 24px;
  height: 23px;
}

.cat-14-icon-small {
  background-position: -329px -182px;
  width: 30px;
  height: 23px;
}

.cat-15-icon-small {
  background-position: -373px -183px;
  width: 30px;
  height: 23px;
}

.cat-16-icon-small {
  background-position: -351px -182px;
  width: 30px;
  height: 23px;
}

.cat-19-icon-small {
  background-position: -191px -332px;
  width: 24px;
  height: 20px;
}

.cat-20-icon-small {
  background-position: -294px -337px;
  width: 20px;
  height: 19px;
}

.cat-99-icon-small {
  background-position: -170px -153px;
  width: 32px;
  height: 22px;
}

.start-icon {
  background-position: -273px -33px;
  width: 10px;
  height: 12px;
}

.finish-icon {
  background-position: -291px -33px;
  width: 10px;
  height: 12px;
}

.start-icon-big {
  background-position: -287px -56px;
  width: 17px;
  height: 19px;
  position: absolute;
  top: 11px;
  left: 12px;
}

.finish-icon-big {
  background-position: -312px -56px;
  width: 17px;
  height: 19px;
  position: absolute;
  top: 11px;
  left: 12px;
}

.close-icon-big {
  background-position: -4px -44px;
  width: 32px;
  height: 32px;
  position: absolute;
  right: -55px;
  cursor: pointer;

  &:hover {
    background-position: -44px -44px;
  }
}

.close-icon {
  background-position: -377px -32px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 24px;

  &:hover {
    background-position: -357px -32px;
  }
}

.close-icon-small {
  background-position: -318px 3px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    background-position: -334px 3px;
  }
}

.left-arrow-small {
  background-position: -207px -33px;
  width: 11px;
  height: 18px;
  margin-right: 8px;
}

.right-arrow-small {
  background-position: -356px -56px;
  width: 11px;
  height: 18px;
  margin-left: 8px;
}

.nrs-sponsor, .mts-sponsor, .pp-sponsor {
  margin-right: 64px;
  display: block;
}

.nrs-sponsor {
  background-position: -397px -38px;
  width: 132px;
  height: 40px;
}

.mts-sponsor {
  background-position: -142px -84px;
  width: 80px;
  height: 40px;
}

.pp-sponsor {
  background-position: -4px -84px;
  width: 132px;
  height: 40px;
}

.footer-link {
  background-position: -4px -186px;
  width: 84px;
  height: 32px;
}

.fb-icon {
  background-position: -245px -33px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.fb-icon-small {
  background-position: -496px -220px;
  width: 16px;
  height: 16px;
}

.favorite-icon {
  background-position: -309px -33px;
  width: 17px;
  height: 17px;
}

.favorite-icon-small {
  background-position: -309px -32px;
  width: 17px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.left-arrow {
  background-position: -84px -44px;
  width: 21px;
  height: 32px;
}

.right-arrow {
  background-position: -178px -44px;
  width: 21px;
  height: 32px;
}

.route-icon {
  background-position: -148px -158px;
  width: 25px;
  height: 17px;
}

.empty-route-icon {
  background-position: -4px -221px;
  width: 134px;
  height: 78px;
  margin: 62px auto 16px auto;
}

.delete-icon {
  background-position: -520px -220px;
  width: 11px;
  height: 16px;
}

.settings-icon {
  background-position: -202px -158px;
  width: 16px;
  height: 16px;
}

.accept-icon {
  background-position: -262px -58px;
  width: 17px;
  height: 14px;
  margin-right: 8px;
}

.cancel-icon {
  background-position: -240px -61px;
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.email-icon {
  background-position: -468px -219px;
  width: 20px;
  height: 13px;
}

.trans-nafta-icon {
  background-position: -111px -274px;
  width: 132px;
  height: 24px;
  margin: 16px 32px 0 0;
}

.disability-icon {
  background-position: -464px -239px;
  width: 26px;
  height: 26px;
}

.exclamation-circle {
  background-position: -453px -274px;
  width: 34px;
  height: 32px;
  margin-right: 16px;
}

.alert-close {
  background-position: -493px -276px;
  width: 25px;
  height: 24px;
  cursor: pointer;
}

.alert-close-warning {
  background-position: -495px -244px;
  width: 25px;
  height: 24px;
  cursor: pointer;
}

.ministarstvo-logo {
  background-position: -52px -307px;
  width: 25px;
  height: 40px;
}

.ruma-logo {
  background-position: -85px -307px;
  width: 36px;
  height: 40px;
}

.vrnjacka-banja-logo {
  background-position: -129px -310px;
  width: 29px;
  height: 40px;
}

.mts-logo {
  background-position: -397px -378px;
  width: 48px;
  height: 24px;
}

.telekom-logo {
  background-position: -363px -346px;
  width: 168px;
  height: 24px;
}

.lasta-logo {
  background-position: -317px -315px;
  width: 182px;
  height: 24px;
  margin: 16px 32px 0 0;
}

.triglav-logo {
  background-position: -453px -378px;
  width: 78px;
  height: 24px;
  margin: 16px 32px 0 0;
}

.tara-nacionalni-park {
  background-position: -72px -359px;
  width: 72px;
  height: 41px;
}

.cloud-tutorial-close {
  background-position: -12px -355px;
  width: 55px;
  height: 54px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.mts-logo-footer {
  background-position: -364px -385px;
  width: 80px;
  height: 40px;
}