.btn {
  height: 32px;
  line-height: 32px;
  color: $buttonColor;
  background-color: $buttonBackgroundColor;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0.002em;
  cursor: pointer;
  text-align: center;
  font-family: $buttonFont;
  text-transform: uppercase;
  outline: none;
  padding: 0 8px;

  &:hover {
    background-color: $buttonHoverBackgroundColor;
  }

  &:disabled {
    opacity: 0.7;
    cursor: initial;

    &:hover {
      background-color: $buttonBackgroundColor;
    }
  }

  &.fb-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $fbButtonBackgroundColor;

    &:hover {
      background-color: $fbButtonHoverBackgroundColor;
    }

    &:disabled {
      opacity: 0.7;
      cursor: initial;

      &:hover {
        background-color: $fbButtonBackgroundColor;
      }
    }

    .btn-text {
      color: $buttonColor;
    }
  }
}