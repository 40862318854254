.auth-wrapper {
  width: 450px;

  .auth-header {
    background-image: url('../../img/007.png');
    height: 168px;
    border-radius: 4px 4px 0 0;
  }

  .forgot-password-wrapper {
    background: #ffffff;
    padding: 16px 32px 32px 32px;
    border-radius: 0 0 4px 4px;

    .form-wrapper {
      .reset-password-header {
        margin-bottom: 8px;
      }

      .input-label {
        font-size: 14px;
        margin-bottom: 8px;
        display: block;
      }

      .input{
        margin-bottom: 16px;
      }

      .btn {
        width: 100%;
      }
    }

    .link {
      font-size: 17px;
    }
  }

  .verification-wrapper {
    background: #ffffff;
    padding: 16px;

    .form-error {
      border-radius: 0 0 4px 4px;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .auth-wrapper {
    width: 100%;
    box-shadow: none;
    transform: none;
    position: relative;
    left: 0;
    top: 0;

    .close-icon-big {
      display: none;
    }

    .auth-header {
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      padding-top: 48%;
      background-image: url('../../img/014.png');
    }
  }
}