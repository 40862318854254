.input {
  height: 32px;
  background-color: $inputBackgroundColor;
  border-radius: 4px;
  width: 100%;
  outline: 0;
  border: 1px solid $inputBorderColor;
  font-size: 15px;
  outline: none;
  padding: 0 8px;

  &:focus {
    border: 1px solid $inputFocusBorderColor;
    background-color: $inputFocusBackgroundColor;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $inputBorderColor inset !important;
    -webkit-text-fill-color: $commonColor !important;
  }

  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px $inputFocusBackgroundColor inset !important;
    -webkit-text-fill-color: $commonColor !important;
  }

  &.error {
    border-color: $errorColor;

    &:focus {
      background-color: $inputFocusBackgroundColor;
    }
  }
}

.search-wrapper {
  position: relative;

  #geocoder {
    padding-left: 36px;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    outline: 0;
    border: 1px solid $inputBorderColor;
    font-size: 15px;
    background-color: #e8e7e7;
    color: $inputColor;
    line-height: 32px;

    &:focus {
      border: 1px solid $inputFocusBorderColor;
      background-color: white;

      &.open-dd {
        border-radius: 4px 4px 0 0;
      }
    }
  }

  //.async-search-wrapper {
  .async-search-wrapper, .local-search-wrapper {
    display: none;
    position: absolute;
    top: 31px;
    background-color: $DDBackgroundColor;
    border: 1px solid $DDBorderColor;
    border-radius: 0 0 4px 4px;
    padding: 8px 0;
    outline:none;

    li {
      height: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 8px;

      &:hover, &.active {
        background-color: $DDBackgroundHoverColor;
      }

      span {
        font-family: $DDFont;
      }

      .search-prev-img {
        width: 26px;
        height: 26px;
        border-radius: 26px;
        margin-right: 8px;
      }
    }

    .not-found {
      padding: 0 8px;
      font-family: $DDFont;
      font-size: 14px;
      line-height: 24px;
      height: 24px;
    }
  }
}

#search-route {
  .mapboxgl-ctrl-geocoder {
    .suggestions {
      li {
        a {
          .geocoder-dropdown-text {
            width: 290px;
          }
        }
      }
    }
  }
}

.geocoder {
  .mapboxgl-ctrl-geocoder {
    box-shadow: none;
    width: initial;
    max-width: initial;

    .mapboxgl-ctrl-geocoder--icon {
      top: 7px;
    }

    .mapboxgl-ctrl-geocoder--input {
      height: 32px;
      border-radius: 4px;
      width: 100%;
      outline: 0;
      border: 1px solid $inputBorderColor;
      font-size: 15px;
      background-color: #e8e7e7;
      color: $inputColor;

      &:focus {
        border: 1px solid $inputFocusBorderColor;
        border-radius: 4px 4px 0 0;
        background-color: white;
      }
    }

    .suggestions-wrapper {
      a {
        width: 100%;
        padding: 4px 8px;
      }
    }
  }
}

.suggestions-wrapper {
  .suggestions {
    top: 31px;
    border: 1px solid $inputFocusBorderColor;
    border-radius: 0 0 4px 4px;
    padding: 8px 0;

    li {
      line-height: 32px;
      letter-spacing: 0.002em;
      cursor: pointer;
      display: flex;
      align-items: center;

      &.not-found {
        padding-left: 8px;
      }

      .mapbox-gl-geocoder--error {
        display: none;
      }

      span {
        font-family: $DDFont;
      }

      .search-prev-img {
        width: 26px;
        height: 26px;
        border-radius: 26px;
        margin-right: 8px;
      }

      .route-icon {
        margin-left: auto;
        margin-top: 3px;
      }
    }
  }
}

.mapboxgl-ctrl-geocoder--pin-right {
  .mapboxgl-ctrl-geocoder--button {
    top: 5px;
    background-color: white;
    outline: none;

    &:not(:disabled) {
      &:hover {
        background-color: white;
      }
    }
  }
}

.geocoder-dropdown-item {
  display: flex;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 24px
  }

  .geocoder-dropdown-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 24px;
    width: 270px;
    font-family: $DDFont;
    font-size: 16px;
  }
}

.form-error {
  color: $errorColor;
  font-size: 12px;

  &.form_error_two {
    margin-bottom: 16px;
  }

  ul {
    li {
      color: $errorColor;
      font-size: 12px;
    }
  }
}
