.alert_wrapper {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10000;
  -webkit-animation: fadein 0.5s linear forwards;
  animation: fadein 0.5s linear forwards;

  &.fadeout {
    -webkit-animation: fadeout 0.5s linear forwards;
    animation: fadeout 0.5s linear forwards;
  }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}