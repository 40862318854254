@import "colors";

* {
  padding: 0;
  margin: 0;
  font-family: $commonFont;
  box-sizing: border-box;
  color: var(--common-color);

  &:before, &:after {
    box-sizing: border-box;
  }
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}