:root {
  --common-color: #e0e0e0;
  --backdrop-color: rgba(0, 0, 0, 0.7);
  --nav-color: #e0e0e0;
  --nav-background-color: #3c3f41;
  --nav-hover-color: #e0e0e0;
  --nav-hover-background-color: #1976d2;
  --nav-arrow-color: #e0e0e0;
  --nav-divider-color: #616161;
  --nav-list-box-shadow-color: #000000;
  --burger-button-color: #e0e0e0;
  --switch-color: #e0e0e0;
  --switch-circle-color: #3c3f41;
  --side-menu-color: #e0e0e0;
  --side-menu-background-color: #3c3f41;
  --side-menu-border-color: #616161;
  --side-menu-background-hover-color: #1976d2;
  --side-menu-hover-color: #e0e0e0;
  --dark-button-color: #eeeeee;
  --dark-button-background-color: #565656;
  --dark-button-border-color: #808080;
  --light-button-color: #424242;
  --light-button-background-color: #fafafa;
  --light-button-border-color: #d1d1d1;
  --blue-button-color: #eeeeee;
  --blue-button-background-color: #1976d2;
  --blue-button-border-color: #0e4172;
  --red-button-color: #eeeeee;
  --red-button-background-color: #d32f2f;
  --red-button-border-color: #7c1c1c;
  --green-button-color: #eeeeee;
  --green-button-background-color: #4caf50;
  --green-button-border-color: #285b2a;
  --check-box-color: #9e9e9e;
  --check-box-background-color: #444648;
  --radio-color: #9e9e9e;
  --radio-background-color: #444648;
  --table-color: #e0e0e0;
  --table-background-color: #333333;
  --table-border-color: #616161;
  --table-header-background-color: #2b2b2b;
  --table-hover-background-color: #434343;
  --input-background-color: #444648;
  --input-broder-color: #808080;
  --input-color: #e0e0e0;
  --input-focus-border-color: #42a5f5;
  --input-icon-color: #e0e0e0;
  --input-loading-spiner-color: #323232;
  --input-webkit-placeholder-color: rgba(224, 224, 224, 0.6);
  --input-hover-webkit-placeholder-color: rgba(224, 224, 224, 0.3);
  --input-error-color: #d32f2f;
  --input-error-webkit-placeholder-color: rgba(211, 47, 47, 0.7);
  --pagination-border-color: #616161;
  --pagination-color: #e0e0e0;
  --pagination-hover-background-color: #1976d2;
  --pagination-hover-color: #fafafa;
  --pagination-background-color: #444648;
  --dropdown-background-color: #444648;
  --dropdown-color: #e0e0e0;
  --dropdown-border-color: #808080;
  --dropdown-border-hover-color: #9e9e9e;
  --dropdown-triangle-color: #e0e0e0;
  --dropdow-active-border-color: #42a5f5;
  --dropdown-placeholder-color: rgba(224, 224, 224, 0.3);
  --dropdown-focus-placeholder-color: rgba(224, 224, 224, 0.6);
  --dropdown-box-shadow-color: rgba(0, 0, 0, 0.8);
  --dropdown-webkit-scrollbar-color: #3c3c3c;
  --dropdown-webkit-scrollbar-thumb-color: #808080;
  --dropdown-item-broder-color: #565656;
  --dropdown-item-hover-background-color: rgba(0, 0, 0, 0.09);
  --dropdown-selected-item-hover-color: rgba(0, 0, 0, 0.17);
  --dropdown-not-found-text: rgba(224, 224, 224, 0.4);
  --dropdown-label-background-color: #565656;
  --dropdown-label-color: #cdcdcd;
  --dropdown-label-barder-color: #808080;
  --tree-color: #e0e0e0;
  --tree-icon-color: #e0e0e0;
  --tree-hover-color: #e0e0e0;
  --tree-hover-background-color: #1976d2;
  --alert-box-shadow: rgba(0, 0, 0, 0.3);
  --alert-info-background-color: #4880b1;
  --alert-info-color: #f4fbff;
  --alert-warning-background-color: #f5d529;
  --alert-warning-color: #5d4a00;
  --alert-error-background-color: #ec4947;
  --alert-error-color: #fbf0ef;
  --alert-success-background-color: #2daf3b;
  --alert-success-color: #fcfffb;
}

html[data-theme="light"] {
  --common-color: #424242;
  --backgound: #f2f2f2;
  --nav-color: #424242;
  --nav-background-color: #f2f2f2;
  --nav-hover-color: #fdfdfd;
  --nav-arrow-color: #424242;
  --nav-arrow-hover-color: #fdfdfd;
  --nav-divider-color: #d1d1d1;
  --nav-list-box-shadow-color: #757575;
  --burger-button-color: #424242;
  --switch-color: #3c3f41;
  --switch-circle-color: #e0e0e0;
  --side-menu-color: #424242;
  --side-menu-background-color: #f2f2f2;
  --side-menu-border-color: #d1d1d1;
  --check-box-color: #9e9e9e;
  --check-box-background-color: #fdfdfd;
  --radio-color: #9e9e9e;
  --radio-background-color: #fdfdfd;
  --table-color: #424242;
  --table-background-color: #fdfdfd;
  --table-border-color: #d1d1d1;
  --table-header-background-color: #eeeeee;
  --table-hover-background-color: #f2f2f2;
  --input-background-color: #fdfdfd;
  --input-broder-color: #9e9e9e;
  --input-color: #424242;
  --input-focus-border-color: #64B5F6;
  --input-icon-color: #424242;
  --input-loading-spiner-color: #cecece;
  --input-webkit-placeholder-color: rgba(66, 66, 66, 0.6);
  --input-hover-webkit-placeholder-color: rgba(66, 66, 66, 0.3);
  --input-error-color: #d32f2f;
  --input-error-webkit-placeholder-color: rgba(211, 47, 47, 0.7);
  --pagination-border-color: #d1d1d1;
  --pagination-color: #424242;
  --pagination-background-color: #fdfdfd;
  --dropdown-background-color: #fdfdfd;
  --dropdown-color: #424242;
  --dropdown-border-color: #bdbdbd;
  --dropdown-border-hover-color: #9e9e9e;
  --dropdown-triangle-color: #424242;
  --dropdow-active-border-color: #64B5F6;
  --dropdown-placeholder-color: rgba(66, 66, 66, 0.3);
  --dropdown-focus-placeholder-color: rgba(66, 66, 66, 0.6);
  --dropdown-box-shadow-color: rgba(34, 36, 38, 0.2);
  --dropdown-webkit-scrollbar-color: #dbdbdb;
  --dropdown-webkit-scrollbar-thumb-color: #ababab;
  --dropdown-item-broder-color: #f5f5f5;
  --dropdown-item-hover-background-color: rgba(0, 0, 0, 0.05);
  --dropdown-selected-item-hover-color: rgba(0, 0, 0, 0.03);
  --dropdown-not-found-text: rgba(0, 0, 0, 0.4);
  --dropdown-label-background-color: #e8e8e8;
  --dropdown-label-color: #616161;
  --dropdown-label-barder-color: #bdbdbd;
  --tree-color: #424242;
  --tree-icon-color: #424242;
  --tree-hover-color: #fdfdfd;
  --alert-box-shadow: rgba(0, 0, 0, 0.3);
  --alert-info-background-color: #4880b1;
  --alert-info-color: #f4fbff;
  --alert-warning-background-color: #f5d529;
  --alert-warning-color: #5d4a00;
  --alert-error-background-color: #ec4947;
  --alert-error-color: #fbf0ef;
  --alert-success-background-color: #2daf3b;
  --alert-success-color: #fcfffb;
}
