@import "colors";
@import "fonts";
@import "sprite";
@import "mixin/mixin";
@import "../partial/header";
@import "src/assets/scss/partial/footer";
@import "../partial/burger_icon";
@import "../partial/UI/button";
@import "../partial/UI/link";
@import "../partial/UI/input";
@import "../partial/UI/triangle";
@import "../partial/UI/separator";
@import "../partial/UI/tabs";
@import "../partial/side_menu";
@import "../partial/UI/backdrop";
@import "../partial/UI/spinner";
@import "../partial/UI/back_link";
@import "../partial/UI/required_login";
@import "../partial/initial_loading";
@import "../partial/map";
@import "../partial/filter";
@import "../partial/rc_slider";
@import "../partial/popup";
@import "../partial/marker";
@import "../partial/route_trip";
@import "../partial/auth";
@import "../partial/route";
@import "../partial/edit_route";
@import "../partial/favorite";
@import "../libs/imp_ui/common/common";
@import "../libs/imp_ui/alert";
@import "../partial/UI/alert_wrapper";
@import "../partial/UI/cloud_tutorial";
@import "../partial/UI/checkbox";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  vertical-align: baseline;
  font-family: $commonFont;
  color: $commonColor;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;

  &:active, &:hover {
    outline: 0;
  }
}

.popup-overplay {
  overflow: overlay;
  height: 100%;
  width: 100%;
  position: absolute;
}

.hidden {
  display: none;
}

.initial-mts-video {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url('https://cdn.meetserbia.rs/images/static/loading_patern.png'), radial-gradient(white, #b4edf5);
  background-position:left top;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}