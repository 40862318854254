.burger-logo {
  width: 52px;
  height: 48px;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
  padding: 12px 8px;
  margin-right: 24px;

  div {
    width: 36px;
    height: 4px;
    background-color: $burgerBackgroundColor;
    border-radius: 4px;
    margin-bottom: 6px;
  }
}