//.mapboxgl-popup {
//  .mapboxgl-popup-content {
//    padding: 0;
//    border-radius: 4px;
//    background: none;

    .marker-info-wrapper {
      position: absolute;
      z-index: 100;

      .marker-info {
        align-items: center;
        border-radius: 4px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
        display: flex;
        width: 220px;
        margin-bottom: 2px;
        background: $markerBackgroundColor;
        cursor: pointer;

        img {
          width: 60px;
          border-radius: 4px 0 0 4px;
        }

        div {
          padding: 0 8px;
          overflow: hidden;

          h5 {
            font-size: 14px;
            font-family: $headerFont;
            letter-spacing: 0.002em;
            text-overflow: ellipsis;
            //white-space: nowrap;
            //overflow: hidden;
            //width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            position: relative;
          }

          span {
            font-size: 12px;
            color: #1689a7;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            display: block;

            &.cat-1 {
              color: #1887A4;
            }

            &.cat-2 {
              color: #B51F5A;
            }

            &.cat-3 {
              color: #5E3EAD;
            }

            &.cat-4 {
              color: #FFC107;
            }

            &.cat-5 {
              color: #A5C63B;
            }

            &.cat-6 {
              color: #E94F67;
            }

            &.cat-7 {
              color: #e4032f;
            }

            &.cat-8 {
              color: #3DB76F;
            }

            &.cat-9 {
              color: #00897B;
            }

            &.cat-10 {
              color: #F4257A;
            }

            &.cat-11 {
              color: #17B3BF;
            }

            &.cat-12 {
              color: #F99164;
            }

            &.cat-13 {
              color: #B87333;
            }

            &.cat-14 {
              color: #3F52B2;
            }
          }
        }
      }

      .pop-up-options {
        display: flex;

        .favorite-wrapper {
          background: $markerBackgroundColor;
          width: 32px;
          height: 32px;
          margin-right: 2px;
          border-radius: 4px 0 0 4px;
          cursor: pointer;
          position: relative;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

          &:hover, &.added {
            background-color: $crimson;

            .favorite-icon-small {
              background-position: -333px -32px;
            }
          }
        }

        .add-route-wraper {
          background: $markerBackgroundColor;
          width: 186px;
          height: 32px;
          display: flex;
          cursor: pointer;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          justify-content: center;
          border-radius: 0 4px 4px 0;

          &:hover, &.added {
            .add-route-text {
              color: white;
            }

            .route-icon {
              background-position: -207px -60px;
            }

            &.cat-1 {
              background-color: #1887A4;
            }

            &.cat-2 {
              background-color: #B51F5A;
            }

            &.cat-3 {
              background-color: #5E3EAD;
            }

            &.cat-4 {
              background-color: #FFC107;
            }

            &.cat-5 {
              background-color: #A5C63B;
            }

            &.cat-6 {
              background-color: #E94F67;
            }

            &.cat-7 {
              background-color: #e4032f;
            }

            &.cat-8 {
              background-color: #3DB76F;
            }

            &.cat-9 {
              background-color: #00897B;
            }

            &.cat-10 {
              background-color: #F4257A;
            }

            &.cat-11 {
              background-color: #17B3BF;
            }

            &.cat-12 {
              background-color: #F99164;
            }

            &.cat-13 {
              background-color: #B87333;
            }

            &.cat-14 {
              background-color: #3F52B2;
            }

            &.cat-19 {
              background-color: #2971b2;
            }

            &.cat-20 {
              background-color: #125598;
            }
          }

          .route-icon {
            margin: 8px;
          }

          .add-route-text {
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 0.002em;
            font-family: $popUpAddRouteFont;
          }
        }
      }
    }
  //}
//}

.mapboxgl-popup-tip {
  display: none;
}

.marker {
  background-image: url('../../img/005.png');
  width: 40px;
  height: 47px;
  cursor: pointer;

  .router-position-info {
    position: absolute;
    left: 14px;
    font-size: 24px;
    line-height: 38px;
    font-family: $routerWaypointFont;
  }
}