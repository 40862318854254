.required-login-wrapper {
  padding: 20px;
  margin: 0 auto;

  h3 {
    font-size: 22px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 48px;
    line-height: 26px;
  }

  .button-wrapper {
    padding: 0 16px 48px 16px;
    display: flex;
    flex-direction: column;

    .btn {
      margin-bottom: 16px;

      &.fb-button {
        text-transform: none;
      }
    }
  }
}