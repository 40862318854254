.route-content {
  width: 384px;
  position: absolute;
  z-index: 250;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
  background-color: $routeBackgroundColor;

  .route-header-image {
    border-radius: 4px 4px 0 0;
  }

  .route-wrapper {
    padding: 16px 8px;

    .search-wrapper {
      z-index: 100;
      margin-bottom: 8px;

      .async-search-wrapper {
        width: 100%;
      }
    }

    .route-overlay {
      position: relative;

      .route-spots-wrapper {
        padding-right: 9px;
      }

      .empty-route-wrapper {
        width: 300px;
        margin: 0 auto;
        display: block;
        text-align: center;

        .empty-route-text {
          span {
            font-size: 18px;
            font-family: $routeListFont;

            &.logo-bold {
              color: #1689a7;
            }
          }
        }
      }

      .route-modal {
        position: absolute;
        background-color: $routeModalBackgroundColor;
        opacity: 0.5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      .route-spots-wrapper {
        width: 100%;

        &>div {
          border-radius: 4px;
        }
        .dragable-list {
            display: flex;
            flex-wrap: wrap;
            cursor: pointer;
            align-items: center;
            height: 66px;
            padding: 8px 0;
            position: relative;
            outline: none;
            border-bottom: 1px solid #e8e7e7;
            background-color: white;

            &:hover {
              background-color: #e8e7e7;
            }

            &:last-child {
            border-bottom: none;
            }

            .route-text {
              font-family: $routeListFont;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: block;
              width: 290px;
            }

            .img-info-wrapper {
              margin-right: 8px;
              box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
              border-radius: 50px;
              width: 50px;
              height: 50px;

              .start-finish-wrapper {
                width: 19px;
                height: 19px;
                position: absolute;
                background-color: white;
                border-radius: 19px;
                box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-family: $startFinishFont;
                font-size: 12px;
              }

              img {
                border-radius: 50px;
                border: 3px solid #8e8e8e;
                width: 50px;
                height: 50px;
                display: block;
              }
            }

            .router-divider {
              //padding-left: 23px;
              padding: 0 8px;
              display: flex;
              height: 16px;
              align-items: center;
              width: 100%;
              z-index: 10;

              .vertical-divider {
                border-left: 3px solid #8e8e8e;
                height: 16px;
                margin-right: 16px;
                font-size: 12px;
                letter-spacing: 0.002em;
              }

              span {
                margin-right: 8px;
                font-size: 12px;
              }

              .horizontal-divider {
                border-top: 1px solid $borderColor;
                flex: 1 1 auto;
              }
            }
          }
      }

      .button-wrapper {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .btn {
          width: 180px;
        }
      }
    }
  }
}