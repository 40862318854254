.header-wrapper {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $headerBackgroundColor;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
  height: 48px;

  .header-group {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;

    &.left {
      left: 0;
    }

    &.right {
      right: 8px;
    }

    .btn {
      margin-right: 32px;
      width: 192px;
    }

    .search-wrapper {
      width: 320px;
      margin-right: 16px;

      .async-search-wrapper {
        width: 320px;
      }
    }

    .lang-wrapper, .account-wrapper {
      height: 32px;
      border-radius: 4px;
      cursor: pointer;
      background-color: $buttonBackgroundColor;

      &:hover {
        background-color: $buttonHoverBackgroundColor;

        .header-dd-wrapper {
          visibility: visible;
        }
      }

      .header-dd-wrapper {
        position: absolute;
        top: 48px;
        right: 0;
        border-radius: 4px;
        background-color: $DDBackgroundColor;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
        padding: 8px 0;
        width: 220px;
        visibility: hidden;
        transition: opacity 200ms cubic-bezier(0, 0, 0.25, 1);

        ul {
          li {
            line-height: 32px;
            letter-spacing: 0.002em;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 36px;
            border-bottom: 1px solid $borderColor;
            font-size: 15px;
            padding: 0 16px;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background-color: $DDBackgroundHoverColor;
            }
          }
        }
      }
    }

    .lang-wrapper {
      padding: 0 8px;
      display: flex;
      align-items: center;
      margin-right: 8px;
      position: relative;

      .lang-text {
        color: $buttonColor;
        font-family: $buttonFont;
        text-transform: uppercase;
      }
    }

    .account-wrapper {
      width: 32px;
      padding: 6px 8px 0 8px;

      .header-dd-wrapper {
        ul {
          li {
            padding: 0;

            a {
              height: 36px;
              line-height: 36px;
              width: 100%;
              padding: 0 16px;
              align-items: center;
              display: flex;
            }
          }
        }
      }
    }
  }
}