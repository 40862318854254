.side-menu {
  position: fixed;
  width: 384px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: $slideMenuBackgroundColor;
  box-sizing: border-box;
  transition: transform 0.4s ease-out;

  &.open {
    transform: translateX(0);
  }

  &.close {
    transform: translateX(-100%);
  }

  img {
    display: block;
  }

  nav {
    width: 100%;

    ul {
      li {
        border-bottom: 1px solid #d0cfcf;
        cursor: pointer;

        a {
          display: flex;
          align-items: center;
          height: 64px;
          padding: 0 32px;

          &:hover {
            background-color: $slideMenuHoverBackgroundColor;
          }

          span {
            font-size: 18px;
            letter-spacing: 0.002em;
          }
        }
      }
    }
  }
}