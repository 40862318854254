.filter-wrapper {
  position: absolute;
  z-index: 70;
  left: 8px;
  top: 56px;
  background-color: $filterBackgroundColor;
  border-radius: 4px;
  width: 384px;
  height: 48px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);

  &.bottom {
    top: 112px;
    z-index: 60;
  }

  .category-list-wrapper {
    display: flex;

    .category-list {
      width: 48px;
      display: flex;
      align-items: center;
      height: 48px;
      justify-content: center;
      cursor: pointer;
      position: relative;

      &:hover {
        .category-dd-wrapper {
          display: block;
        }
      }

      &:hover, &.active {

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &.cat-1-wrapper {
          background-color: #1887A4;
        }

        &.cat-2-wrapper {
          background-color: #B51F5A;
        }

        &.cat-3-wrapper {
          background-color: #5E3EAD;
        }

        &.cat-4-wrapper {
          background-color: #FFC107;
        }

        &.cat-5-wrapper {
          background-color: #A5C63B;
        }

        &.cat-6-wrapper {
          background-color: #E94F67;
        }

        &.cat-7-wrapper {
          background-color: #e4032f;
        }

        &.cat-8-wrapper {
          background-color: #3DB76F;
          border-radius: 0 4px 4px 0;
        }

        &.cat-9-wrapper {
          background-color: #00897B;
        }

        &.cat-10-wrapper {
          background-color: #F4257A;
        }

        &.cat-11-wrapper {
          background-color: #17B3BF;
        }

        &.cat-12-wrapper {
          background-color: #F99164;
        }

        &.cat-13-wrapper {
          background-color: #B87333;
        }

        &.cat-14-wrapper {
          background-color: #3F52B2;
        }

        &.cat-15-wrapper {
          background-color: #F5CC18;
          border-radius: 0 4px 4px 0;
        }

        &.cat-16-wrapper {
          background-color: #E01B45;
          border-radius: 0 4px 4px 0;
        }

        &.cat-19-wrapper {
          background-color: #2971b2;
        }

        &.cat-20-wrapper {
          background-color: #125598;
        }

        .cat-1-icon-big {
          background-position: -445px -119px;
        }

        .cat-2-icon-big {
          background-position: -480px -119px;
        }

        .cat-3-icon-big {
          background-position: -292px -154px;
        }

        .cat-4-icon-big {
          background-position: -229px -153px;
        }

        .cat-5-icon-big {
          background-position: -259px -153px;
        }

        .cat-6-icon-big {
          background-position: -327px -156px;
        }

        .cat-7-icon-big {
          background-position: -465px -412px;
        }

        .cat-8-icon-big {
          background-position: -388px -153px;
        }

        .cat-9-icon-big {
          background-position: -420px -153px;
        }

        .cat-10-icon-big {
          background-position: -448px -153px;
        }

        .cat-11-icon-big {
          background-position: -482px -153px;
        }

        .cat-12-icon-big {
          background-position: -508px -153px;
        }

        .cat-13-icon-big {
          background-position: -514px -119px;
        }

        .cat-14-icon-big {
          background-position: -506px -187px;
        }

        .cat-15-icon-big {
          background-position: -473px -187px;
        }

        .cat-16-icon-big {
          background-position: -439px -186px;
        }

        .cat-19-icon-big {
          background-position: -199px -314px;
        }

        .cat-20-icon-big {
          background-position: -263px -314px;
        }
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    .category-hover-info {
      padding: 0 12px;
      font-size: 14px;

      span {
        display: flex;
        width: max-content;
        line-height: 24px;
        height: 24px;

        &.cat-1 {
          color: #1887A4;
        }

        &.cat-2 {
          color: #B51F5A;
        }

        &.cat-3 {
          color: #5E3EAD;
        }

        &.cat-4 {
          color: #FFC107;
        }

        &.cat-5 {
          color: #A5C63B;
        }

        &.cat-6 {
          color: #E94F67;
        }

        &.cat-7 {
          color: #e4032f;
        }

        &.cat-8 {
          color: #3DB76F;
        }

        &.cat-9 {
          color: #00897B;
        }

        &.cat-10 {
          color: #F4257A;
        }

        &.cat-11 {
          color: #17B3BF;
        }

        &.cat-12 {
          color: #F99164;
        }

        &.cat-13 {
          color: #B87333;
        }

        &.cat-14 {
          color: #3F52B2;
        }

        &.cat-15 {
          color: #F5CC18;
        }

        &.cat-16 {
          color: #E01B45;
        }

        &.cat-19 {
          color: #2971b2;
        }

        &.cat-20 {
          color: #125598;
        }
      }
    }

    .category-dd-wrapper {
      padding: 8px 8px 0 8px;
      width: 384px;
      display: none;

      .triangle-wrapper {
        margin-left: 4px;
      }
    }

    .category-dd-wrapper, .category-hover-info {
      position: absolute;
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
      z-index: 100;

      .triangle-wrapper {
        right: initial;

        .triangle-with-shadow {
          width: 24px;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          border-radius: 20px;
          cursor: pointer;
          display: block;
          height: 22px;
          padding: 0 8px;
          margin-right: 8px;
          margin-bottom: 8px;
          font-size: 12px;
          line-height: 20px;

          &.cat-dd-1-wrapper, &.cat-dd-2-wrapper, &.cat-dd-3-wrapper, &.cat-dd-4-wrapper, &.cat-dd-5-wrapper, &.cat-dd-6-wrapper, &.cat-dd-7-wrapper, &.cat-dd-8-wrapper, &.cat-dd-9-wrapper, &.cat-dd-10-wrapper, &.cat-dd-11-wrapper, &.cat-dd-12-wrapper, &.cat-dd-13-wrapper, &.cat-dd-14-wrapper, &.cat-dd-15-wrapper, &.cat-dd-16-wrapper, &.cat-dd-19-wrapper, &.cat-dd-20-wrapper {
            &:hover, &.active {
              color: white;
            }
          }

          &.cat-dd-1-wrapper {
            border: 1px solid #1887A4;
            color: #1887A4;

            &:hover, &.active {
              background-color: #1887A4;
            }
          }

          &.cat-dd-2-wrapper {
            border: 1px solid #B51F5A;
            color: #B51F5A;

            &:hover, &.active {
              background-color: #B51F5A;
            }
          }

          &.cat-dd-3-wrapper {
            border: 1px solid #5E3EAD;
            color: #5E3EAD;

            &:hover, &.active {
              background-color: #5E3EAD;
            }
          }

          &.cat-dd-4-wrapper {
            border: 1px solid #FFC107;
            color: #FFC107;

            &:hover, &.active {
              background-color: #FFC107;
            }
          }

          &.cat-dd-5-wrapper {
            border: 1px solid #A5C63B;
            color: #A5C63B;

            &:hover, &.active {
              background-color: #A5C63B;
            }
          }

          &.cat-dd-6-wrapper {
            border: 1px solid #E94F67;
            color: #E94F67;

            &:hover, &.active {
              background-color: #E94F67;
            }
          }

          &.cat-dd-7-wrapper {
            border: 1px solid #e4032f;
            color: #e4032f;

            &:hover, &.active {
              background-color: #e4032f;
            }
          }

          &.cat-dd-8-wrapper {
            border: 1px solid #3DB76F;
            color: #3DB76F;

            &:hover, &.active {
              background-color: #3DB76F;
            }
          }

          &.cat-dd-9-wrapper {
            border: 1px solid #00897B;
            color: #00897B;

            &:hover, &.active {
              background-color: #00897B;
            }
          }

          &.cat-dd-10-wrapper {
            border: 1px solid #F4257A;
            color: #F4257A;

            &:hover, &.active {
              background-color: #F4257A;
            }
          }

          &.cat-dd-11-wrapper {
            border: 1px solid #17B3BF;
            color: #17B3BF;

            &:hover, &.active {
              background-color: #17B3BF;
            }
          }

          &.cat-dd-12-wrapper {
            border: 1px solid #F99164;
            color: #F99164;

            &:hover, &.active {
              background-color: #F99164;
            }
          }

          &.cat-dd-13-wrapper {
            border: 1px solid #B87333;
            color: #B87333;

            &:hover, &.active {
              background-color: #B87333;
            }
          }

          &.cat-dd-14-wrapper {
            border: 1px solid #3F52B2;
            color: #3F52B2;

            &:hover, &.active {
              background-color: #3F52B2;
            }
          }

          &.cat-dd-15-wrapper {
            border: 1px solid #F5CC18;
            color: #F5CC18;

            &:hover, &.active {
              background-color: #F5CC18;
            }
          }

          &.cat-dd-16-wrapper {
            border: 1px solid #E01B45;
            color: #E01B45;

            &:hover, &.active {
              background-color: #E01B45;
            }
          }

          &.cat-dd-19-wrapper {
            border: 1px solid #2971b2;
            color: #2971b2;

            &:hover, &.active {
              background-color: #2971b2;
            }
          }

          &.cat-dd-20-wrapper {
            border: 1px solid #125598;
            color: #125598;

            &:hover, &.active {
              background-color: #125598;
            }
          }
        }
      }
    }

    .__react_component_tooltip {
      display: block !important;
      border-radius: 4px;
      box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
      padding: 0 12px;
      font-size: 14px;
      width: max-content;
      line-height: 24px;
      height: 24px;

      &.show {
        opacity: 1;
        border: none;
      }

      &.cat-1 {
        color: #1887A4;
      }

      &.cat-2 {
        color: #B51F5A;
      }

      &.cat-3 {
        color: #5E3EAD;
      }

      &.cat-4 {
        color: #FFC107;
      }

      &.cat-5 {
        color: #A5C63B;
      }

      &.cat-6 {
        color: #E94F67;
      }

      &.cat-7 {
        color: #e4032f;
      }

      &.cat-8 {
        color: #3DB76F;
      }

      &.cat-9 {
        color: #00897B;
      }

      &.cat-10 {
        color: #F4257A;
      }

      &.cat-11 {
        color: #17B3BF;
      }

      &.cat-12 {
        color: #F99164;
      }

      &.cat-13 {
        color: #B87333;
      }

      &.cat-14 {
        color: #3F52B2;
      }

      &.cat-15 {
        color: #F5CC18;
      }

      &.cat-16 {
        color: #E01B45;
      }

      &.cat-19 {
        color: #2971b2;
      }

      &.cat-20 {
        color: #125598;
      }
    }
  }
}

.simplebar-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:after {
    display: none !important;
  }

  &:before {
    display: none !important;
  }
}

.pin-filter-wrapper {
  width: 384px;
  z-index: 50;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  position: absolute;
  background-color: white;
  padding: 12px 12px 0 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .pin-filter {
    width: 174px;
    box-shadow: 1px 1px 3px 1px #b1b1b1;
    border-radius: 4px;
    height: 174px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
    transition: transform .3s;

    &:hover {
      //opacity: 0.65;
      transform: scale(1.05);
    }

    img {
      display: block;
    }

    .pin-header {
      position: absolute;
      bottom: 0;
      background: linear-gradient(transparent 0, rgba(0,0,0,0.8) 100%);
      width: 100%;
      color: white;
      font-size: 16px;
      font-family: $headerFont;
      word-wrap: break-word;
      //padding: 8px;
      //text-overflow: ellipsis;
      //white-space: nowrap;
      //overflow: hidden;
      padding: 8px 8px 4px 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

    }
  }

  .empty-filter {
    padding-top: 88px;
    margin: 0 auto;
    text-align: center;

    img {
      width: 172px;
      margin-bottom: 16px;
    }

    h3 {
      font-size: 28px;
      color: $resarchColor;
      font-weight: normal;
      font-family: $headerFont;
    }
  }
}

.locaion-wrapper {
  position: absolute;
  left: 8px;
  top: 168px;
  width: 384px;
  z-index: 50;

  .prewiev-header-wrapper {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
    height: 24px;
    margin-bottom: 8px;
    position: relative;
    border-radius: 4px;


    .category-info {
      width: 384px;
      position: absolute;
      left: 0;
      top: 0;
      height: 24px;
      border-radius: 4px;
      cursor: pointer;
      padding-left: 12px;
      border: 1px solid white;

      &:last-child {
        cursor: initial;
      }

      .category-text {
        text-transform: uppercase;
        color: white;
        font-size: 12px;
        letter-spacing: 0.002em;
        line-height: 23px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        padding-right: 16px;
      }

      .sprite-bg {
        position: absolute;
        right: 0;
        top: 0;
      }

      &.cat-1 {
        background-color: #1887A4;
      }

      &.cat-2 {
        background-color: #B51F5A;
      }

      &.cat-3 {
        background-color: #5E3EAD;
      }

      &.cat-4 {
        background-color: #FFC107;
      }

      &.cat-5 {
        background-color: #A5C63B;
      }

      &.cat-6 {
        background-color: #E94F67;
      }

      &.cat-7 {
        background-color: #e4032f;
      }

      &.cat-8 {
        background-color: #3DB76F;
      }

      &.cat-9 {
        background-color: #00897B;
      }

      &.cat-10 {
        background-color: #F4257A;
      }

      &.cat-11 {
        background-color: #17B3BF;
      }

      &.cat-12 {
        background-color: #F99164;
      }

      &.cat-13 {
        background-color: #B87333;
      }

      &.cat-14 {
        background-color: #3F52B2;
      }

      &.cat-15 {
        background-color: #F5CC18;
      }

      &.cat-16 {
        background-color: #E01B45;
      }

      &.cat-19 {
        background-color: #2971b2;
      }

      &.cat-20 {
        background-color: #125598;
      }

      &.cat-99 {
        background-color: #8e8e8e;
      }
    }
  }
}