@import "node_modules/react-awesome-slider/src/core/styles";

.popup-content {
  width: 1200px;
  @include center(true, false);
  z-index: 200;
  top: 56px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  .popup-wrapper {
    padding: 32px 24px;
    background-color: $recommendationBackgroundColor;
    display: flex;
    flex-wrap: wrap;

    .popup-article {
      width: 368px;
      height: 164px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      position: relative;
      margin: 0 24px 24px 0;
      cursor: pointer;

      &:hover {
        background-color: $articleHoverBackgroundColor;
      }

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      img {
        float: left;
        display: block;
        border-radius: 4px 0 0 4px;
        height: 164px;
      }

      .popup-article-body {
        float: left;
        display: block;
        width: 204px;
        padding: 16px;

        h4 {
          font-size: 14px;
          margin-bottom: 8px;
          font-family: $recommendationHeaderFont;
        }

        p {
          font-size: 12px;
          margin-bottom: 8px;
        }

        .link {
          position: absolute;
          bottom: 16px;
          right: 16px;
        }
      }
    }
  }

  .popup-header-wrapper {
    height: 400px;
    position: relative;
    border-radius: 4px 4px 0 0;
    background-image: url('../../img/006.png');

    .popup-header {
      position: absolute;
      color: $recommendationHeaderColor;
      left: 32px;
      bottom: 16px;
      font-size: 48px;
      font-family: $headerFont;
      letter-spacing: 0.002em;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
  }

  &.recommendation-popup {
    .popup-header-wrapper {
      background-image: url('../../img/009.png');
    }
  }

  &.guide-popup {
    .popup-wrapper {
      padding: 16px 64px 32px 32px;

      .guide-content {
        display: flex;

        img {
          border-radius: 4px;
          margin-right: 48px;
          height: 304px;

        }

        .guides-wrapper {
          h3 {
            font-size: 34px;
            margin-bottom: 32px;
            font-family: $headerFont;
          }
        }
      }
    }
  }

  &.guides-popup {
    .popup-wrapper {
      .popup-article {
        .popup-article-body {
          float: left;
          display: block;
        }
      }
    }
  }

  &.marker-popup {
    .marker-gallery-wrapper {
      position: relative;

      img {
        display: block;
      }

      .marker-heading {
        position: absolute;
        color: #fff;
        left: 32px;
        bottom: 16px;
        font-size: 48px;
        font-family: $headerFont;
        letter-spacing: .002em;
        z-index: 10;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
      }

      .full-gallery-button {
        position: absolute;
        bottom: 16px;
        z-index: 10;
        border: 1px solid white;
        border-radius: 4px;
        left: 16px;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.7);

        img {
          width: 64px;
        }
      }
    }

    .marker-header {
      background: white;
      border-bottom: 1px solid $borderColor;
      height: 60px;
      padding: 0 32px;
      position: relative;

      .header-group {
        align-items: center;
        display: flex;
        height: 100%;
        position: absolute;
        top: 0;

        &.left {
          left: 32px;
        }

        &.right {
          right: 32px;

          .disability-wrapper {
            width: 30px;
            height: 30px;
            border: 2px solid #125598;
            border-radius: 4px  ;
          }
        }

        .btn {
          margin-right: 16px;

          &.fb-button {
            background-color: #415B95 !important;
            padding: 0 8px !important;
          }
        }

        .like-wrapper {
          width: 32px;
          height: 32px;
          border: 1px solid #E4185C;
          border-radius: 4px;
          background-color: white;
          cursor: pointer;
          justify-content: center;
          outline: none;
          display: flex;
          align-items: center;

          &:hover, &.added {
            background-color: #E4185C;

            .favorite-icon {
              background-position: -333px -33px;
            }
          }
        }

        .tag-wrapper, .disability-wrapper {
          height: 30px;
          width: 60px;
          display: flex;
          justify-content: center;
          border-radius: 30px;
          align-items: center;
          margin-left: 16px;

          .__react_component_tooltip {
            z-index: 2147483647;
            opacity: 1;
            padding: 4px 16px;
            width: max-content;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

            &:before, &:after {
              content: none;
            }

            &.disability-info {
              color: #125598;
              border: 1px solid #125598;
            }

            &.cat-1 {
              border: 1px solid #1887A4;
              color: #1887A4;
            }

            &.cat-2 {
              border: 1px solid #B51F5A;
              color: #B51F5A;
            }

            &.cat-3 {
              border: 1px solid #5E3EAD;
              color: #5E3EAD;
            }

            &.cat-4 {
              border: 1px solid #FFC107;
              color: #FFC107;
            }

            &.cat-5 {
              border: 1px solid #A5C63B;
              color: #A5C63B;
            }

            &.cat-6 {
              border: 1px solid #E94F67;
              color: #E94F67;
            }

            &.cat-7 {
              border: 1px solid #e4032f;
              color: #e4032f;
            }

            &.cat-8 {
              border: 1px solid #3DB76F;
              color: #3DB76F;
            }

            &.cat-9 {
              border: 1px solid #00897B;
              color: #00897B;
            }

            &.cat-10 {
              border: 1px solid #F4257A;
              color: #F4257A;
            }

            &.cat-11 {
              border: 1px solid #17B3BF;
              color: #17B3BF;
            }

            &.cat-12 {
              border: 1px solid #F99164;
              color: #F99164;
            }

            &.cat-13 {
              border: 1px solid #B87333;
              color: #B87333;
            }

            &.cat-14 {
              border: 1px solid #3F52B2;
              color: #3F52B2;
            }

            &.cat-15 {
              border: 1px solid #F5CC18;
              color: #F5CC18;
            }


            &.cat-19 {
              border: 1px solid #2971b2;
              color: #2971b2;
            }


            &.cat-20 {
              border: 1px solid #125598;
              color: #125598;
            }
          }

          &.cat-1-wrapper {
            border: 2px solid #1887A4;
          }

          &.cat-2-wrapper {
            border: 2px solid #B51F5A;
          }

          &.cat-3-wrapper {
            border: 2px solid #5E3EAD;
          }

          &.cat-4-wrapper {
            border: 2px solid #FFC107;
          }

          &.cat-5-wrapper {
            border: 2px solid #A5C63B;
          }

          &.cat-6-wrapper {
            border: 2px solid #E94F67;
          }

          &.cat-7-wrapper {
            border: 2px solid #e4032f;
          }

          &.cat-8-wrapper {
            border: 2px solid #3DB76F;
          }

          &.cat-9-wrapper {
            border: 2px solid #00897B;
          }

          &.cat-10-wrapper {
            border: 2px solid #F4257A;
          }

          &.cat-11-wrapper {
            border: 2px solid #17B3BF;
          }

          &.cat-12-wrapper {
            border: 2px solid #F99164;
          }

          &.cat-13-wrapper {
            border: 2px solid #B87333;
          }

          &.cat-14-wrapper {
            border: 2px solid #3F52B2;
          }

          &.cat-15-wrapper {
            border: 2px solid #F5CC18;
          }

          &.cat-16-wrapper {
            border: 2px solid #E01B45;
          }

          &.cat-19-wrapper {
            border: 2px solid #2971b2;
          }

          &.cat-20-wrapper {
            border: 2px solid #125598;
          }
        }
      }
    }

    .popup-wrapper {
      display: flex;
      padding: 32px;

      .html-content {
        width: 794px;
        margin-right: 32px;
      }

      .side-banner-wrapper {
        width: 310px;

        a {
          width: 100%;
          margin-bottom: 16px;
          display: block;

          img {
            width: 100%;
          }
        }

        .banner-gallery-wrapper {
          margin-bottom: 16px;

          .awssld__wrapper {
            height: 176px !important;
          }

          .awssld__timer {
            //display: none;
            --timer-background-color: rgba(0, 0, 0, 0) !important;
            background-color: rgba(0, 0, 0, 0) !important;

            &.awssld__timer--animated, &.awssld__timer--run {
              --timer-background-color: rgba(0, 0, 0, 0) !important;
              background-color: rgba(0, 0, 0, 0) !important;
            }
          }

          .awssld__bar {
            --timer-background-color: rgba(0, 0, 0, 0) !important;
            background-color: rgba(0, 0, 0, 0) !important;
          }

          .awssld__bar--end {
            --timer-background-color: rgba(0, 0, 0, 0) !important;
            background-color: rgba(0, 0, 0, 0) !important;
          }

          .awssld__bar--active {
            --timer-background-color: rgba(0, 0, 0, 0) !important;
            background-color: rgba(0, 0, 0, 0) !important;
          }

          .awssld__controls{
            display: none;
          }
        }
      }
    }

    .html-content {
      .marker-heading {
        padding-bottom: 16px;
      }

      .html-wrapper {
        a {
          font-size: 14px;
          font-family: $linkFont;

          &:hover {
            color: $linkHoverColor;
          }
        }
      }
    }
  }

  &.profile {
    .profile-header-wrapper {
      background-image: url('../../img/008.png');
      height: 400px;
      position: relative;

      .profile-info-box {
        @include center(true, true);
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        width: 280px;
        height: 280px;
        padding-top: 56px;

        .avatar-image {
          width: 110px;
          margin: 0 auto 16px auto;
          display: block;
          border-radius: 110px;
        }

        .name-text {
          color: white;
          font-size: 24px;
          text-align: center;
          font-family: $profileNameFont;
          display: block;
        }
      }
    }

    .react-tabs {
      .react-tabs__tab-list {
        .react-tabs__tab {
          height: 64px;
          line-height: 64px;
          font-size: 20px;
          width: inherit;
          padding: 0 32px;
        }
      }

      .tab-wrapper {
        padding: 24px;
      }
    }
  }

  &.delete-modal {
    width: 480px;
    background-color: $recommendationBackgroundColor;
    z-index: 300;
    @include center(true, true);

    h3 {
      padding: 32px;
      font-size: 24px;
      font-weight: normal;
      font-family: $popUpHeaderFont;
    }

    .pop-up-divider {
      border-top: 1px solid $dividerColor;
    }

    .button-wrapper {
      padding: 32px;
      display: flex;
      justify-content: flex-end;

      .btn {
        padding: 0 16px;
        margin-left: 24px;
        font-size: 20px;
        display: flex;
        align-items: center;

        &.accept {
          background-color: $acceptBackgroundColor;

          &:hover {
            background-color: $acceptHoverBackgroundColor;
          }
        }

        &.dalete {
          background-color: $deleteColor;

          &:hover {
            background-color: $deleteHoverColor;
          }
        }

        span {
          color: $buttonColor;
          font-family: $buttonFont;
        }
      }
    }
  }

  &.login-modal {
    width: 400px;
    background-color: $recommendationBackgroundColor;
    z-index: 300;
    @include center(true, true);

    .required-login-wrapper {

      h3 {
        margin-bottom: 24px;
      }

      .button-wrapper {
        padding: 0 16px 0 16px;
      }
    }
  }

  &.tutorial-wrapper {
    width: 672px;
    padding: 32px 16px 16px 16px;
    background-color: white;
    @include center(true, true);
    z-index: 300;

    .close-icon-big {
      top: 0;
    }

    h2 {
      font-weight: normal;
      text-align: center;
      margin-bottom: 24px;
      font-size: 26px;
      font-family: $headerFont;
    }

    p {
      padding: 0 111px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      font-family: $headerFont;
    }

    img {
      display: block;
      box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      margin-top: 40px;
    }
  }

  &.about-us-wrapper {
    img {
      display: block;
    }

    .about-us-body {
      background-color: white;
      padding: 40px 96px;

      h3 {
        font-family: $headerFont;
        font-weight: normal;
        font-size: 32px;
        margin-bottom: 32px;
      }

      p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 16px;

        span {
          font-family: "Manrope-SemiBold";
          font-size: 19px;
        }
      }
    }
  }

  &.contact-us-popup {
    width: 720px;
    background-color: $recommendationBackgroundColor;

    .popup-header-wrapper {
      background-image: url('../../img/013.jpg');
      height: 300px;

      .popup-header {
        font-size: 40px;
      }
    }

    .contact-us-wrapper {
      padding: 40px 64px;

      .contact-us-header {
        font-weight: normal;
        font-size: 22px;
        font-family: $headerFont;
        margin-bottom: 24px;
      }

      .form-wrapper {
        .input-wrapper {
          margin-bottom: 16px;

          .input-label {
            font-size: 14px;
            letter-spacing: 0.002em;
            margin-bottom: 8px;
            display: block;
          }

          textarea {
            height: initial;
          }
        }

        .btn {
          margin:  32px auto 0 auto;
          padding: 0 32px;
          display: table;
        }
      }

      .feedback-text {
        text-align: center;
        font-size: 24px;
        font-weight: normal;
        font-family: $headerFont;
        line-height: 30px;
      }
    }
  }

  &.impresum-wrapper {
    img {
      display: block;
    }

    .impresum-body {
      background-color: white;
      padding: 40px 96px;

      div {
        margin-bottom: 16px;
      }

      h3 {
        font-family: $headerFont;
        font-weight: normal;
        font-size: 32px;
        margin-bottom: 32px;
      }

      .impresum-header {
        font-family: $headerFont;
        font-size: 18px;
      }

      .impresum-link {
        color: $lightseagreen;
        text-decoration: underline;
      }
    }
  }

  &.policy-wrapper {
    img {
      display: block;
    }

    .policy-body {
      background-color: white;
      padding: 40px 96px;

      h1, h2, h3, h4 {
        font-weight: bold;
      }

      h2 {
        margin-bottom: 16px;
        font-size: 22px;
      }

      p {
        font-size: 14px;
        margin-bottom: 12px;

        &.last-text {
          margin-bottom: 32px;
        }

        h4 {
          display: contents;
          font-size: 15px;
        }

        ul {
          list-style: disc;
          padding: 8px 0 0 32px;

          ul {
            list-style-type: '-   ';
            padding: 0 0 0 16px;
          }
        }

        .link {
          font-size: 15px;
        }
      }

      .last-update-text {
        margin-bottom: 32px;
      }
    }
  }
}

.awssld__wrapper {
  height: 450px !important;
  border-radius: 4px 4px 0 0;

  .awssld__bar {
    background-color: $darkcyan !important;
  }

  .awssld__controls {
    button {
      height: 100%;
      width: 80px;
      top: 0;
      background: linear-gradient(to right, transparent 0, rgba(0, 0, 0, 0.7) 100%);

      &:last-child {
        background: linear-gradient(to left, transparent 0, rgba(0, 0, 0, 0.7) 100%);
      }

      &:hover {
        .left-arrow {
          background-position: -113px -44px;
        }

        .right-arrow {
          background-position: -149px -44px;
        }
      }
    }
  }
}

.full-gallery-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;

  .awssld__wrapper {
    height: 100% !important;
    border-radius: 0;

    img {
      width: initial;
      height: initial;
      max-width: 100%;
      max-height: 100%;
      object-fit: initial;
      top: initial;
      left: initial;
    }
  }
}

.gallery-close{
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10000;
}