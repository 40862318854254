@font-face {
  font-family: 'Manrope-Regular';
  src: url('../../fonts/Manrope-Regular.eot');
  src: url('../../fonts/Manrope-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Manrope-Regular.svg#Manrope-Regular') format('svg'),
  url('../../fonts/Manrope-Regular.ttf') format('truetype'),
  url('../../fonts/Manrope-Regular.woff') format('woff'),
  url('../../fonts/Manrope-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: url('../../fonts/Manrope-SemiBold.eot');
  src: url('../../fonts/Manrope-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Manrope-SemiBold.svg#Manrope-SemiBold') format('svg'),
  url('../../fonts/Manrope-SemiBold.ttf') format('truetype'),
  url('../../fonts/Manrope-SemiBold.woff') format('woff'),
  url('../../fonts/Manrope-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

$Manrope: 'Manrope-Regular';
$ManropeSemiBold: 'Manrope-SemiBold';

$commonFont: $Manrope;
$buttonFont: $ManropeSemiBold;
$linkFont: $ManropeSemiBold;
$DDFont: $ManropeSemiBold;
$headerFont: $ManropeSemiBold;
$routerHeaderFont: $ManropeSemiBold;
$routeListFont: $ManropeSemiBold;
$startFinishFont: $ManropeSemiBold;
$recommendationHeaderFont: $ManropeSemiBold;
$backPopupFont: $ManropeSemiBold;
$routerWaypointFont: $ManropeSemiBold;
$popUpAddRouteFont: $ManropeSemiBold;
$tabFont: $ManropeSemiBold;
$profileNameFont: $ManropeSemiBold;
$popUpHeaderFont: $ManropeSemiBold;