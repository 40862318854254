.triangle-wrapper {
  position: absolute;
  top: -16px;
  right: 8px;

  .triangle-with-shadow {
    width: 80px;
    height: 16px;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      width: 17px;
      height: 17px;
      background: $triangleBackgroundColor;
      transform: rotate(45deg);
      top: 8px;
      right: 4px;
      box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
    }
  }
}

