$white: #ffffff;
$lavender: #e8e7e7;
$silver: #c8c8c8;
$gray: #8e8e8e;
$dimGray: #686868;
$black: #000000;
$tomato: #fc5b5b;
$crimson: #e01b45;
$crimsonRed: #d83025;
$lightseagreen: #14a1b8;
$darkcyan: #1689a7;
$darkslateblue: #415B95;
$steelblue: #5475AF;
$darkSeaGreen: #72CE7F;
$mediumSeaGreen: #5DBA72;

$commonColor: $dimGray;
$borderColor: $silver;
$headerBackgroundColor: $white;
$burgerBackgroundColor: $darkcyan;
$buttonColor: $white;
$buttonBackgroundColor: $darkcyan;
$buttonHoverBackgroundColor: $lightseagreen;
$fbButtonBackgroundColor: $darkslateblue;
$fbButtonHoverBackgroundColor: $steelblue;
$linkHoverColor: $lightseagreen;
$inputColor: $dimGray;
$inputBackgroundColor: $lavender;
$inputBorderColor: $lavender;
$inputFocusBackgroundColor: $white;
$inputFocusBorderColor: $darkcyan;
$triangleBackgroundColor: $white;
$DDBackgroundColor: $white;
$DDBorderColor: $darkcyan;
$DDBackgroundHoverColor: $lavender;
$slideMenuBackgroundColor: $white;
$slideMenuHoverBackgroundColor: $lavender;
$filterBackgroundColor: $white;
$routeBackgroundColor: $white;
$routeHeaderBackgroundColor: $lightseagreen;
$routerHeaderColor: $white;
$routeModalBackgroundColor: $white;
$recommendationHeaderColor: $white;
$recommendationBackgroundColor: $white;
$footerBackgroundColor: $darkcyan;
$footerColor: $white;
$footerBorderColor: $white;
$footerHoverColor: $silver;
$articleHoverBackgroundColor: $lavender;
$markerBackgroundColor: $white;
$favoriteColor: $crimson;
$tabsBackgroundColor: $lavender;
$tabBoxShadow: $gray;
$tabsActiveBackgrountColor: $white;
$tabsPanelBackgroundcolor: $white;
$separatorColor: $silver;
$errorColor: $crimsonRed;
$deleteColor: $crimsonRed;
$deleteHoverColor: $tomato;
$dividerColor: $silver;
$acceptBackgroundColor: $mediumSeaGreen;
$acceptHoverBackgroundColor: $darkSeaGreen;
$spinnerColor: $darkcyan;
$resarchColor: $darkcyan;
$inputErrorBorderColor: $crimsonRed;