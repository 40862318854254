.route-overlay {
  .router-radius-wrapper {
    position: absolute;
    bottom: 40px;
    width: 100%;
    background-color: #ffffff;

    .router-radius-info-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .radius-info-header {
        font-size: 12px;
        font-weight: normal;
      }

      .radius-info-text {
        font-size: 12px;
      }
    }

    .router-distance-info-wrapper {
      margin-bottom: 8px;

      .distance-info-header {
        font-size: 12px;
      }

    }

    .rc-slider {
      height: 10px;
      padding: 0;
      border-radius: 4px;
      border: 1px solid #14a1b8;

      .rc-slider-rail {
        height: 8px;
        top: 0;
        background-color: rgba(20, 161, 184, 0.2);
        border-radius: 4px;
      }

      .rc-slider-track {
        height: 8px;
        top: 0;
        background-color: rgba(20, 161, 184, 0.55);
        border-radius: 4px;
      }

      .rc-slider-step {
        height: 8px;
        top: 0;
        border-radius: 4px;
      }

      .rc-slider-handle {
        height: 16px;
        top: 1px;
        width: 16px;
        border: 2px solid #14a1b8;

        &:active, &:hover {
          box-shadow: 0 0 5px #57c5f7;
          cursor: grabbing;
          z-index: 10;
        }
      }

      .rc-slider-mark {
        top: 0;
        height: 8px;
      }
    }

    .route-mode-wrapper {
      background: #fff;
      padding: 2px;
      border-radius: 15px;
      vertical-align: middle;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      display: flex;
      margin-bottom: 8px;

      .route-mode {

        cursor: pointer;
        vertical-align: top;
        display: block;
        border-radius: 16px;
        padding: 3px 5px;
        font-size: 12px;
        color: rgba(0,0,0, 0.5);
        line-height: 20px;
        text-align: center;
        width: 33.333%;
        float: left;

        &.active {
          background: #eee;
        }
      }
    }

    .all-routes-wrapper {
      margin-bottom: 8px;

      .route-info-wrapper {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-bottom: 4px;
        cursor: pointer;
        font-size: 12px;
        padding: 4px;

        &:hover, &.active {
          background: #eee;
        }

        div {
          display: flex;
          justify-content: space-between;

          span {
            max-width: 250px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}