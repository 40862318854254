.initial-loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: url('https://cdn.meetserbia.rs/images/static/loading_patern.png'), radial-gradient(white, #b4edf5);
  background-position:left top;
  overflow: hidden;

  .loading_logo {
    @include center(true, false);
    top: 64px;
  }

  .loading_vehicle {
    @include center(true, false);
    width: 750px;
    top: 160px;
  }
}

.spinners__box {
  top: 32px;
  z-index: 100;
  position: absolute;
  display: flex;
  align-items: center;
  @include center(true, false);
}

.dot-loader {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: $spinnerColor;
  position: relative;
  -webkit-animation: 1.2s scaleDown ease-in-out infinite ;
  animation: 1.2s scaleDown ease-in-out infinite ;
}

.dot-loader:nth-child(2){
  margin: 0 16px;
  -webkit-animation: 1.2s scaleDown ease-in-out infinite .15555s;
  animation: 1.2s scaleDown ease-in-out infinite .15555s;
}

.dot-loader:nth-child(3){
  -webkit-animation: 1.2s scaleDown ease-in-out infinite .300000s;
  animation: 1.2s scaleDown ease-in-out infinite .300000s;
}

@media only screen and (max-height: 850px) {
  .initial-loading {
    .loading_logo {
      width: 350px;
    }

    .loading_vehicle {
      width: 550px;
      top: 187px;
    }
  }
}

@-webkit-keyframes scaleDown{
  0%, 80%, 100%{
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40%{
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleDown{
  0%, 80%, 100%{
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40%{
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}