.all-favorite-wrapper {
  display: flex;
  flex-wrap: wrap;

  .favorite-box {
    margin: 0 24px 24px 0;
    width: 172px;
    box-shadow: 1px 1px 3px 1px #b1b1b1;
    border-radius: 4px;
    height: 190px;
    position: relative;
    overflow: hidden;

    &:nth-child(6n+6) {
      margin-right: 0;
    }

    .favorite-info-wrapper {
      height: 150px;
      display: block;
      overflow: hidden;

      &:hover {
        opacity: 0.65;
      }

      h5 {
        position: absolute;
        top: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        width: 100%;
        color: white;
        font-size: 16px;
        font-family: $headerFont;
        word-wrap: break-word;
        padding: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .options-wrapper {
      height: 32px;
      padding: 8px;

      .option-button-wrapper {
        width: 24px;
        height: 24px;
        background-color: $fbButtonBackgroundColor;
        cursor: pointer;
        float: left;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
        display: flex;

        &.fb-button {
          width: initial;
          padding: 0 8px;
          background-color: #415B95 !important;
          padding: 0 8px !important;

          &:hover {
            background-color: $fbButtonHoverBackgroundColor;
          }

          .fb-icon-small {
            margin-right: 4px;
          }

          span {
            font-size: 14px;
            line-height: 24px;
            color: white;
            font-family: "Manrope-SemiBold";
          }
        }

        &.delete {
          background-color: $deleteColor;
          float: right;

          &:hover {
            background-color: $deleteHoverColor;
          }
        }
      }
    }
  }
}