.back-link {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  width: fit-content;

  .back-text {
    line-height: 32px;
    font-size: 18px;
    font-family: $backPopupFont;
  }

  &:hover {
    .back-text {
      color: $linkHoverColor;
    }

    .left-arrow-small {
      background-position: -226px -33px;
    }

    .right-arrow-small {
      background-position: -337px -56px;
    }
  }
}