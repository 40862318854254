@import "common/common";

.popup-container {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}