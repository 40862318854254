.footer-wrapper {
  background-color: $footerBackgroundColor;
  padding-top: 16px;
  border-radius: 0 0 4px 4px;

  .footer-section {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $footerBorderColor;
    padding: 16px 32px;

    &:last-child {
      border-bottom: none;
      justify-content: space-between;
      align-items: center;
    }

    .footer-list {
      width: 284px;
      margin-bottom: 24px;

      li {
        margin-bottom: 2px;
        color: $footerColor;

        &.footer-list-header {
          font-family: $headerFont;
          font-size: 17px;
        }

        &.footer-list-link {
          text-decoration: underline;
          font-size: 14px;
        }

        a {
          color: $footerColor;

          &:hover {
            color: $footerHoverColor;
          }

          span {
            color: $footerColor;

            &:hover {
              color: $footerHoverColor;
            }
          }
        }
      }
    }

    .sponsor-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        margin-right: 24px;
        display: flex;
        align-items: center;

        .ministarstvo-logo, .ministarstvo-logo, .ruma-logo, .vrnjacka-banja-logo, .tara-nacionalni-park {
          float: left;
          margin-right: 8px;
        }

        span {
          font-size: 11px;
          color: #ffffff;
          width: 120px;
          float: left;
          line-height: 11px;
          font-family: $ManropeSemiBold;
        }
      }
    }

    .copyright-text {
      color: $footerColor;
      font-size: 14px;
    }
  }
}