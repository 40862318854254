.react-tabs {
  .react-tabs__tab-list {
    display: flex;
    background-color: $tabsBackgroundColor;
    box-shadow: inset 0 -3px 10px -5px $tabBoxShadow;
    overflow: hidden;

    .react-tabs__tab {
      outline: none;
      width: 50%;
      text-align: center;
      cursor: pointer;
      height: 32px;
      line-height: 32px;
      font-family: $tabFont;
      border-radius: 4px 4px 0 0;

      &.react-tabs__tab--selected {
        color: $linkHoverColor;
        background: $tabsActiveBackgrountColor;
        box-shadow: 0 0 15px -3px $tabBoxShadow;
      }
    }
  }

  .tab-wrapper {
    background: $tabsPanelBackgroundcolor;
    padding: 16px 32px 32px 32px;
    border-radius: 0 0 4px 4px;

    .react-tabs__tab-panel {
      .form-wrapper {
        .input-wrapper {
          margin-bottom: 16px;

          .input-label {
            font-size: 14px;
            letter-spacing: 0.002em;
            margin-bottom: 8px;
            display: block;
          }

          .form-error {
            margin-top: 8px;
          }
        }

        .g-recaptcha {
          position: relative;

          &>div {
            margin: 0 auto 8px auto;
          }

          #recaptcha_localization {
            background-color: #f9f9f9;
            top: 24px;
            left: 90px;
            position: absolute;
            width: 190px;
          }
        }

        .btn {
          width: 100%;
          margin-bottom: 16px;
        }

        .link {
          margin-bottom: 16px;
          display: block;
          width: fit-content;
          cursor: pointer;
        }

        .privacy-wrapper {
          margin-bottom: 16px;
          font-size: 14px;

          .register-link {
            color: $linkHoverColor;
          }
        }
      }

      .fb-button {
        width: initial;
        text-transform: none;
        margin: 0 auto;
      }

      .edit-profle-wrapper {
        .form-wrapper {
          display: flex;
          justify-content: space-between;

          .left, .right {
            width: 480px;
          }

          h3 {
            font-weight: normal;
            font-family: $headerFont;
            font-size: 21px;
            letter-spacing: 0.002em;
            margin-bottom: 16px;
          }

          .avatar-wrapper {
            height: 180px;
            width: 180px;
            border-radius: 50%;
            background-size: cover;
            border: 4px solid white;
            overflow: hidden;
            position: relative;
            transform: translate3d(0, 0, 0);
            margin: 48px auto 16px auto;

            input {
              cursor: pointer;
              height: 100%;
              left: 0;
              opacity: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }

          .link {
            font-size: 14px;
            margin: 0 auto 64px auto;
            display: block;
            width: max-content;
            cursor: pointer;
          }

          .btn {
            width: initial;
            padding: 0 48px;
            float: right;
          }
        }
      }

      .verification-wrapper {
        p {
          margin-bottom: 8px;
        }

        .link {
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}