.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  font-size: 14px;
}
.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $separatorColor;
}
.separator::before {
  margin-right: .25em;
}
.separator::after {
  margin-left: .25em;
}