.alert_wrapper {
  height: 64px;
  width: 300px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 2px 2px 3px var(--alert-box-shadow);

  &.alert_info {
    background: var(--alert-info-background-color);

    .fa-exclamation-circle, .fa-exclamation-triangle, .fa-ban, .fa-check, .alert_text, .fa-times {
      color: var(--alert-info-color);
    }
  }

  &.alert_warning {
    background: var(--alert-warning-background-color);

    .fa-exclamation-circle, .fa-exclamation-triangle, .fa-ban, .fa-check, .alert_text, .fa-times {
      color: var(--alert-warning-color);
    }
  }

  &.alert_error {
    background: var(--alert-error-background-color);

    .fa-exclamation-circle, .fa-exclamation-triangle, .fa-ban, .fa-check, .alert_text, .fa-times {
      color: var(--alert-error-color);
    }
  }

  &.alert_success {
    background: var(--alert-success-background-color);

    .fa-exclamation-circle, .fa-exclamation-triangle, .fa-ban, .fa-check, .alert_text, .fa-times {
      color: var(--alert-success-color);
    }
  }

  .fa-exclamation-circle, .fa-exclamation-triangle, .fa-ban, .fa-check {
    margin-right: 16px;
    font-size: 28px;
  }

  .alert_text {
    margin-right: 16px;
    width: 190px;
    font-size: 14px;
    //font-family: $alertTextFont;
  }

  .fa-times {
    font-size: 23px;
    cursor: pointer;
  }
}