.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;

  input {
    cursor: pointer;
    outline: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid var(--check-box-color);
    border-radius: 4px;
    //background-color: var(--check-box-background-color);
    margin-right: 4px;

    &+.check {
      position: absolute;
      cursor: pointer;
      display: block;
      border-style: solid;
      border-color: var(--check-box-color);
      transform: rotate(45deg);
      opacity: 0;
      top: 1px;
    }

    &:checked, &:indeterminate {
      &+.check {
        opacity: 1;
      }
    }
  }

  &.small {
    input {
      width: 16px;
      height: 16px;

      &+.check {
        left: 5px;
        top: 3px;
        width: 6px;
        height: 11px;
        border-width: 0 2px 2px 0;
      }

      &:indeterminate {
        &+.check {
          left: 3px;
          width: 10px;
          height: 2px;
          top: 7px;
          transform: rotate(0deg);
        }
      }
    }
  }

  &.medium {
    input {
      width: 24px;
      height: 24px;

      &+.check {
        left: 7px;
        width: 9px;
        height: 18px;
        border-width: 0 3px 3px 0;
      }
    }
  }

  &.large {
    input {
      width: 32px;
      height: 32px;

      &+.check {
        left: 9px;
        width: 13px;
        height: 25px;
        border-width: 0 4px 4px 0;
      }
    }
  }

  .label {

  }
}