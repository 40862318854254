.edit-route-wrapper {
  background-color: white;
  padding: 16px 32px 32px 32px;

  .back-link {
    margin-bottom: 32px;
  }

  .form-wrapper {
    .input-wrapper {
      width: 800px;
      margin: 0 auto 16px auto;

      .input-label {
        font-size: 24px;
        margin-bottom: 16px;
        display: block;
      }
    }

    textarea {
      height: initial;
      margin-bottom: 16px;
    }
  }

  .button-wraper {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    .btn {
      padding: 0 32px;
      margin-right: 32px;

      &.delete {
        background-color: $deleteColor;

        &:hover {
          background-color: $deleteHoverColor;
        }
      }
    }
  }

  .next-prev-wrapper {
    display: flex;
    justify-content: space-between;

    a {
      margin-bottom: 0;
    }
  }
}