.cloud-wrapper {
  z-index: 100;
  position: absolute;
  width: 324px;

  &.tutorial-1 {
    top: 40px;
    left: 380px;

    span {
      position: absolute;
      top: 98px;
      left: 100px;
      font-size: 21px;
    }
  }

  &.tutorial-2 {
    top: 80px;
    left: 385px;

    span {
      position: absolute;
      top: 96px;
      left: 8px;
      font-size: 21px;
      text-align: center;
      width: 280px;
    }
  }

  &.tutorial-3 {
    top: 300px;
    left: 400px;

    span {
      position: absolute;
      top: 84px;
      left: 70px;
      font-size: 21px;
    }
  }
}